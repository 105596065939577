// Turkish
export default{
    form: {
        startSurveyButton: "Başlayalım",
        nextButton: "Sonraki",
        submitAnswerButton: "Cevabı Gönder",
        continueButton: "Devam",
        finishButton: "Bitir",
        headerPercentageCompletion: 'tamamlandı',
        headerQuestionsCompleted: 'soru',
        headerDefaultErrorMessage: 'Ups! Bir hata oluştu.',
        brandingPoweredBy: 'Tarafından destekleniyor',
        brandingPromoWhySurvey: 'Neden anket yapasınız ki, <b>{product}</b> kullanabilirsiniz?',
        brandingPromoGetStarted: 'Bugün başlayın!',
        submissionPending: "Voiceform gönderiliyor. Lütfen tarayıcıyı kapatmayın.",
        submissionCanCloseWindow: "Voiceform başarıyla gönderildi. Pencereyi kapatabilirsiniz 👍",
        mediaAttachmentOverlayCloseButton: "Kapat",
        expiredFormHeader: "Uğradığınız için teşekkürler!",
        expiredFormMessage: "Bu anketin süresi dolmuş gibi görünüyor. Lütfen anket sahibine haber verin.",
        notPublishedFormHeader: "Voiceform yayınlanmadı!",
        notPublishedFormMessage: "Bu anket yayınlanmamış gibi görünüyor. Lütfen anket sahibine haber verin.",
        notPublishedFormRetryButton: "Tekrar Dene",
        errorFormHeader: "Oops!",
        errorFormMessage: "Bir hata olabilir. Lütfen sonra tekrar deneyin.",
        errorFormRetryButton: "Tekrar Dene",
        emptyFormHeader: "Uğradığınız için teşekkürler!",
        emptyFormMessage: "Oops! Bu formda doldurulacak soru veya alan bulunmuyor. Sorunu çözmek için form sahibine haber verin. Anlayışınız için teşekkürler!",

        restoreFormHeader: "Bir form üzerinde çalışıyorsunuz!",
        restoreFormMessage: "Kaldığınız yerden devam etmek ister misiniz?",
        restoreFormButton: "Forma Devam Et",
        restoreFormButtonNew: "Baştan Başla",

        audioPermissionHeader: "Sesle cevap vermek ister misiniz?",
        audioPermissionMessage: "Konuşmak, cevap vermeyi hızlı ve kolay yapar.",
        audioPermissionAcceptButton: "Evet, istiyorum",
        audioPermissionDenyButton: "Hayır, teşekkürler",
        audioPermissionErrorHeader: "Mikrofon mevcut değil",
        audioPermissionErrorMessage: "Mikrofon erişimini etkinleştirmek için tarayıcı izinlerinizi kontrol edin.",
        audioPermissionErrorButton: "Tamam",
        audioRecorderFailedUpload: "Kayıt yüklenemedi",
        silenceDetectorError: "Sesiniz duyulmuyor. Mikrofon ayarlarını kontrol edin. İpucu: Harici mikrofon olabilir.",
        audioRecorderButtonRecord: "Kayda Başla",
        audioRecorderButtonRecordMore: "Daha Fazla Kaydet",
        voiceResponseSelectionMessage: "Nasıl cevap vermek istiyorsunuz?",
        voiceResponseSelectionOrDivider: "veya",
        voiceResponseTextInputPlaceholder: "Cevabı yazın",
        voiceResponseTextInputCharacterCounterMinMessage: "En az {min} karakter girin",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} karakter (en az {min} karakter)",
        voiceResponseTextInputCharacterValidationMessage: "{count} karakter (en az {min} karakter)",

        // checkbox
        checkboxValidationTooFew: "En az {min} seçenek seçmelisiniz",
        checkboxValidationTooMany: "En fazla {max} seçenek seçmelisiniz",
        checkboxNoneOfTheAboveOption: "Hiçbiri",
        checkboxInvalidAnswer: "Geçersiz cevap.",

        // datepicker
        datePickerPlaceholder: "Tarih seçin",

        // dropdown
        // email
        emailLabel: "E-posta",
        emailInvalid: "Geçersiz e-posta.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Video kaydet",
        fileUploadPluginScreenCast: "Ekran Kaydı",
        fileUploadVideoImportFilesDefault: 'Bir video kaydedin veya yükleyin:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Bir video kaydetme seçeneği seçin:',
        fileUploadVideoImportFilesNoCamera: 'Ekran kaydı için bir düğmeye basın',
        fileUploadVideoImportFilesNoScreenCast: 'Bir video kaydetmek için bir düğmeye basın',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Video kaydetme seçeneği yok',

        // matrix
        matrixValidationMessage: "Cevap geçerli.",
        matrixRow: "Satır",
        matrixColumn: "Sütun",
        matrixRowRequired: "Satır {rowTitle} gerekli.",
        matrixRadioGroup: "Radyo grubu",
        matrixCheckboxGroup: "Onay kutusu grubu",
        matrixGroupRequired: "Satır {rowTitle} için. {type} {groupTitle} gerekli.",
        matrixColumnRequired: "Satır {rowTitle} için. Sütun {columnTitle} gerekli.",
        matrixColumnInvalid: "Satır {rowTitle} için. Sütun {columnTitle} geçersiz.",
        matrixRequired: "Gerekli.",
        matrixNumericMustBeNumber: "Bir sayı olmalı.",
        matrixNumericMustBeGreaterThenMin: "{min} değerinden büyük olmalı.",
        matrixNumericMustBeLessThenMax: "{max} değerinden küçük olmalı.",
        matrixNumericMustBeInteger: "Tam sayı olmalı.",
        matrixNumericInvalidNumber: "Geçersiz sayı.",

        // name
        nameLabel: "Ad",
        nameInvalid: "Ad ve soyad girin.",

        // nps
        npsNotLikely: "Hiç olası değil",
        npsExtremelyLikely: "Çok olası",

        // numeric input
        numericInputRequired: "Gerekli.",
        numericInputMustBeNumber: "Bir sayı olmalı.",
        numericInputMustBeGreaterThenMin: "{min} değerinden büyük olmalı.",
        numericInputMustBeLessThenMax: "{max} değerinden küçük olmalı.",
        numericInputMustBeInteger: "Tam sayı olmalı.",
        numericInputInvalidNumber: "Geçersiz sayı.",
        numericInputPlaceholder: "Sayı girin",

        // phone
        phoneInvalid: "Telefon numarası geçersiz.",
        phoneCountrySelectorLabel: 'Ülke kodu',
        phoneCountrySelectorError: 'Ülke seçin',
        phoneNumberLabel: 'Telefon numarası',
        phoneExample: 'Örnek:',

        // boolean
        booleanYesLabel: "Evet",
        booleanNoLabel: "Hayır",

        //questionStep
        questionStepAudioQuestionLabel: "Ses Sorusu",

        // errors
        invalidPhoneNumber: "{phone} geçersiz telefon numarası.",
        invalidEmail: "{email} geçersiz e-posta adresi.",
        questionIsRequired: "Bu soru gerekli.",
        answerIsNotValid: "Bu cevap geçerli değil.",
        unfinishedProbingDialogError: "Lütfen diyaloğu tamamlayın.",
        cannotResumePartialResponse: "Kısmi yanıt devam ettirilemiyor.",
        failedToSubmitForm: "Form gönderilemedi. İnternet bağlantınızı kontrol edin ve tekrar deneyin."
    }
}
